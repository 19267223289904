<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('AppPrevious.AddEdit.concludePrevious')"
      :back-button-text="$t('AppPrevious.AddEdit.previousButton')"
      :next-button-text="$t('AppPrevious.AddEdit.following')"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- Previo general -->
      <tab-content
        :title="$t('AppPrevious.AddEdit.generalPrevius')"
        :before-change="validationGeneral"
      >
        <validation-observer
          ref="refGeneralValidationRules"
          tag="form"
        >
          <b-row>
            <!-- Recinto fiscal field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Recinto fiscal"
                rules="required"
              >
                <b-form-group
                  :label="$t('AppPrevious.columns.enclosure')"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="previousData.recintoFiscal"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :options="recintosFiscales"
                    @input="fetchLineasTransporte(previousData.recintoFiscal.textoAlterno)"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Linea transporte field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Linea transporte"
                rules="required"
              >
                <b-form-group
                  :label="$t('AppPrevious.columns.transportLine')"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="previousData.lineaTransporteId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="option => option.id"
                    :options="lineasTransportes"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Customer field-->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Cliente"
                rules="required"
              >
                <b-form-group
                  :label="$t('AppPrevious.columns.customer')"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="previousData.clienteId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="option => option.id"
                    :options="customers"
                    @input="fetchVendors(previousData.clienteId)"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <!-- vendorWrite -->
              <validation-provider
                #default="validationContext"
                :name="$t('AppPrevious.columns.vendor')"
                rules="required"
              >
                <b-form-group
                  :label="$t('AppPrevious.columns.vendor')"
                  label-for="proveedor"
                >
                  <b-input-group v-if="!vendorWrite">
                    <v-select
                      v-model="previousData.proveedorId"
                      class="width-v-select"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="texto"
                      :reduce="option => option.id"
                      :options="vendors"
                    />
                    <b-input-group-append class="w-7">
                      <b-button
                        variant="primary"
                        @click="vendorWrite = !vendorWrite"
                      >
                        <feather-icon :icon="vendorToggleIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-input-group v-if="vendorWrite">
                    <b-form-input
                      id="proveedor"
                      v-model="previousData.proveedorNombre"
                      name="proveedor"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="vendorToggleIcon"
                        class="cursor-pointer"
                        @click="vendorWrite = !vendorWrite"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback :state="validationContext.errors.length > 0 ? false : null">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="FechaPrevio"
                rules="required"
              >
                <b-form-group
                  :label="$t('AppPrevious.columns.datePrevious')"
                  label-for="fecha-previo"
                >
                  <b-form-datepicker
                    id="fecha-previo"
                    v-model="previousData.fechaPrevio"
                    today-button
                    reset-button
                    close-button
                    locale="es"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="previousData.recintoFiscal && previousData.recintoFiscal.textoAlterno === 'Aérea'"
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="FechaEntrada"
                rules="required"
              >
                <b-form-group
                  :label="$t('AppPrevious.columns.dateEntry')"
                  label-for="fecha-entrada"
                >
                  <b-form-datepicker
                    id="fecha-entrada"
                    v-model="previousData.fechaEntrada"
                    today-button
                    reset-button
                    close-button
                    locale="es"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="previousData.recintoFiscal && previousData.recintoFiscal.textoAlterno === 'Marítima'"
              cols="12"
              md="4"
            >
              <database-select
                v-model="previousData.previoCargaTipoId"
                domain-name="PrevioTiposCarga"
                :label="$t('AppPrevious.AddEdit.typeLoad')"
                validation-rules="required"
                @selected-option="$refs.selectPrevioTipo.fetch(previousData.previoCargaTipoId)"
              />
            </b-col>
            <b-col
              v-if="previousData.recintoFiscal && previousData.recintoFiscal.textoAlterno === 'Marítima'"
              cols="12"
              md="4"
            >
              <database-select
                ref="selectPrevioTipo"
                v-model="previousData.previoTipoId"
                domain-name="PrevioTipos"
                :label="$t('AppPrevious.AddEdit.typePrevious')"
                :clearable="true"
                validation-rules="required"
              />
            </b-col>
            <b-col
              v-if="previousData.recintoFiscal && previousData.recintoFiscal.textoAlterno === 'Aérea'"
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.merchandiseRequestTime')"
              >
                <b-form-timepicker
                  v-model="previousData.horaSolicitudMercancia"
                  locale="en"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="previousData.recintoFiscal && previousData.recintoFiscal.textoAlterno === 'Aérea'"
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.merchandiseDeliveryTime')"
              >
                <b-form-timepicker
                  v-model="previousData.horaEntregaMercancia"
                  locale="en"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="previousData.recintoFiscal && previousData.recintoFiscal.textoAlterno === 'Aérea'"
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('AppPrevious.columns.previousStartTime')"
              >
                <b-form-timepicker
                  v-model="previousData.horaInicioPrevio"
                  locale="en"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="previousData.recintoFiscal"
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('AppPrevious.columns.guideBLMaster')"
                :rules="{required: previousData.recintoFiscal.textoAlterno === 'Marítima' }"
              >
                <b-form-group
                  :label="$t('AppPrevious.columns.guideBLMaster')"
                  label-for="guiaMaster"
                >
                  <b-form-input
                    id="guiaMaster"
                    v-model="previousData.guiaMaster"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="previousData.recintoFiscal && previousData.previoCargaTipoId !== 1"
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('AppPrevious.columns.guideBLHouse')"
                :rules="{required: previousData.recintoFiscal.textoAlterno === 'Aérea' || previousData.previoCargaTipoId == 2 }"
              >
                <b-form-group
                  :label="$t('AppPrevious.columns.guideBLHouse')"
                  label-for="guiaHouse"
                >
                  <b-form-input
                    id="guiaHouse"
                    v-model="previousData.guiaHouse"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <template v-if="showMaritima">
              <b-col
                cols="12"
                md="4"
              >
                <form-input
                  v-model="previousData.numerContenedor"
                  :label="$t('AppPrevious.AddEdit.containerNumber')"
                  :get-validation-state="getValidationState"
                  validation-rules="required"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <form-input
                  v-model="previousData.numeroCandadoInicial"
                  :label="$t('AppPrevious.AddEdit.padlockNumberInitial')"
                  :get-validation-state="getValidationState"
                  validation-rules="required"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
                validation-rules="required"
              >
                <form-input
                  v-model="previousData.numeroCandadoFinal"
                  :label="$t('AppPrevious.AddEdit.padlockNumberFinal')"
                  :get-validation-state="getValidationState"
                  validation-rules="required"
                />
              </b-col>
            </template>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('AppPrevious.AddEdit.packages')"
                rules="required"
              >
                <b-form-group
                  :label="$t('AppPrevious.AddEdit.packages')"
                  label-for="bultos"
                >
                  <b-form-input
                    id="bultos"
                    v-model="previousData.bultos"
                    type="number"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('AppPrevious.AddEdit.totalWeight')"
                rules="required"
              >
                <b-form-group
                  :label="$t('AppPrevious.AddEdit.totalWeight')"
                  label-for="pesoTotal"
                >
                  <b-form-input
                    id="pesoTotal"
                    v-model="previousData.pesoTotal"
                    type="number"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="previousData.recintoFiscal && previousData.recintoFiscal.textoAlterno === 'Aérea'"
              cols="12"
              md="4"
            >
              <database-select
                v-model="previousData.previoDetalleGuiaId"
                domain-name="PrevioDetallesGuias"
                :label="$t('AppPrevious.AddEdit.guideDetail')"
                :clearable="true"
                validation-rules="required"
              />
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.tags')"
              >
                <b-form-checkbox
                  v-model="previousData.etiquetas"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.packageDamaged')"
              >
                <b-form-checkbox
                  v-model="previousData.bultoDañado"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="previousData.bultoDañado"
              cols="12"
              md="4"
            >
              <database-select
                v-model="previousData.previoDanioId"
                domain-name="PrevioDanios"
                :label="$t('AppPrevious.AddEdit.damage')"
                validation-rules="required"
                :clearable="true"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- Documents Tags -->
      <tab-content
        :title="$t('AppPrevious.AddEdit.documentPrevious')"
        :before-change="validateDocuments"
      >
        <b-alert
          v-if="guideRequired"
          show
          dismissible
          fade
          class="mt-1"
          variant="warning"
        >
          <div class="alert-body">
            {{ $t('AppPrevious.AddEdit.guideRequired') }}
          </div>
        </b-alert>
        <b-alert
          v-if="damageRequired"
          show
          dismissible
          fade
          class="mt-1"
          variant="warning"
        >
          <div class="alert-body">
            {{ $t('AppPrevious.AddEdit.damageRequired') }}
          </div>
        </b-alert>
        <validation-observer
          ref="refDocumentsValidation"
          tag="form"
        >
          <database-select
            v-model="auxData.previoDocumentoTipo"
            domain-name="PrevioDocumentosTipo"
            :label="$t('AppPrevious.AddEdit.typeDocument')"
            :reduce="option => option"
          />

          <multiple-file-uploader
            ref="refFilesUploader"
            v-model="filesUploaded"
            :disabled="auxData.previoDocumentoTipo === null"
            class="mb-1"
          />
          <b-button
            :disabled="filesUploaded.length === 0 || isUploadingDocuments"
            class="mb-1"
            @click="onUploadDocuments"
          >
            <b-spinner v-if="isUploadingDocuments" />
            {{ $t('AppPrevious.AddEdit.uploadDocuments') }}
          </b-button>
          <b-table
            :items="previousDocumentsData"
            :fields="filesTableFields"
            class="position-relative"
            responsive
          >
            <template
              #cell(nombreDocumento)="data"
            >
              {{ data.item.archivo }}.{{ data.item.extension }}
            </template>
            <template
              #cell(actions)="data"
            >
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="data.item.previoDocumentoTipoId !== 5"
                  @click="onDeteleDocument(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.extension"
                  @click="onDownloadFile(data.item)"
                >
                  <feather-icon icon="DownloadCloudIcon" />
                  <span class="align-middle ml-50">{{ $t('AppPrevious.AddEdit.downloadFile') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </validation-observer>
      </tab-content>
      <!-- Previo partidas -->
      <tab-content
        :title="$t('AppPrevious.AddEdit.departed')"
        :before-change="validationPartidas"
      >
        <validation-observer
          ref="refPartidasValidation"
          tag="form"
        >
          <previous-add-edit-partida
            v-if="previousData.previoId"
            :previous-id="previousData.previoId"
            :partidas-count.sync="previosPartidasCount"
            :partidas-photos-completed.sync="partidasPhotosCompleted"
            :custom-type="previousData.recintoFiscal.textoAlterno"
          />
        </validation-observer>
      </tab-content>
      <!-- Observaciones generales -->
      <tab-content
        :title="$t('AppPrevious.AddEdit.generalRemarks')"
        :before-change="validationObservations"
      >
        <validation-observer
          ref="refObservationsValidation"
          tag="form"
        >
          <validation-provider
            #default="validationContext"
            name="Observaciones"
            rules=""
          >
            <b-form-group
              :label="$t('AppPrevious.AddEdit.generalRemarks')"
            >
              <b-form-textarea
                v-model="previousData.observaciones"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </tab-content>
      <b-button
        slot="finish"
        variant="success"
        :disabled="endingPrevious"
        @click="endingPrevious = true"
      >
        <b-spinner
          v-if="endingPrevious"
          small
          class="mr-1"
        />
        {{ $t('AppPrevious.AddEdit.concludePrevious') }}
      </b-button>
    </form-wizard>
    <conversation ref="refConversation" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTimepicker,
  BFormCheckbox,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BAlert,
  BFormTextarea,
  BButton,
  BSpinner,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import DatabaseSelect from '@/components/DatabaseSelect.vue'
import FormInput from '@/components/FormInput.vue'
import Conversation from '@/components/Conversation.vue'
import MultipleFileUploader from '@/components/MultipleFileUploader.vue'
import { ref, computed } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, requiredIf } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import optionService from '@/services/option.service'
import previousService from '@/services/previous.service'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import PreviousAddEditPartida from './PreviousAddEditPartida.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormTimepicker,
    BFormCheckbox,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BAlert,
    BFormTextarea,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    DatabaseSelect,
    FormInput,
    BSpinner,
    MultipleFileUploader,
    Conversation,
    ValidationProvider,
    ValidationObserver,

    FormWizard,
    TabContent,

    PreviousAddEditPartida,
  },
  setup() {
    /* Services */
    const {
      fetchOptions,
    } = optionService()
    const {
      fetchPreviousByGuid,
      createPrevious,
      updatePrevious,
      partialUpdatePrevious,
      fetchPreviousDocument,
      fetchPreviousDocuments,
      createPreviousDocument,
      deletePreviousDocument,
    } = previousService()
    /* Refs */
    const refGeneralValidationRules = ref(null)
    const refDocumentsValidation = ref(null)
    const refPartidasValidation = ref(null)
    const refObservationsValidation = ref(null)
    const refConversation = ref(null)
    const endingPrevious = ref(false)

    const guideRequired = ref(false)
    const damageRequired = ref(false)
    const vendorWrite = ref(false)
    const refFilesUploader = ref(null)
    const isUploadingDocuments = ref(false)

    /* Data */
    const blankPreviousnData = {
      previoId: 0,
      recintoFiscalId: null,
      lineaTransporteId: null,
      clienteId: null,
      proveedorId: null,
      horaSolicitudMercancia: null,
      horaEntregaMercancia: null,
      horaInicioPrevio: null,
      fechaEntrada: null,
      fechaPrevio: null,
      previoDetalleGuiaId: null,
      guiaMaster: null,
      guiaHouse: null,
      etiquetas: false,
      cuantificados: false,
      bultoDañado: false,
      previDanioId: null,
      pesoTotal: null,
      bultos: null,
      numeroContenedor: '',
      numeroCandadoFinal: '',
      numeroCandadoInicial: '',
      previoCargaTipoId: '',
      previoTipoId: '',
      conversacionId: null,
      previoDocumentos: [],
      previoPartidas: [],
      usuario: 'Usuario',
      observaciones: '',
    }

    const previousData = ref({})
    const previousDocumentsData = ref([])
    const auxData = ref({
      archivoSeleccionado: '',
      archivoSeleccionadoNombre: '',
      previoDocumentoTipo: null,
    })
    const filesUploaded = ref([])
    const previosPartidasCount = ref(0)
    const partidasPhotosCompleted = ref(false)
    const recintosFiscales = ref([])
    const customers = ref([])
    const vendors = ref([])
    const lineasTransportes = ref([])

    const fetchVendors = customerId => {
      if (customerId) {
        fetchOptions({ nombreDominio: 'Proveedores', llaveBuscar: customerId }, data => {
          vendors.value = data
        })
      }
    }
    const fetchLineasTransporte = tipoAduana => {
      fetchOptions({ nombreDominio: 'LineasTransporte', llaveBuscar: tipoAduana }, data => {
        lineasTransportes.value = data
      })
    }

    if (router.currentRoute.params.guid) {
      fetchPreviousByGuid(router.currentRoute.params.guid, data => {
        previousData.value = data

        fetchVendors(previousData.value.clienteId)
        if (previousData.value.proveedorNombre) vendorWrite.value = true
        fetchLineasTransporte(previousData.value.recintoFiscal.textoAlterno)

        refConversation.value.refetchData(previousData.value.conversacionId)

        // TODO: get previous documents
        fetchPreviousDocuments(previousData.value.previoId, dataDocuments => {
          previousData.value.previoDocumentos = dataDocuments
          previousDocumentsData.value = dataDocuments
        })
      })
    } else {
      previousData.value = JSON.parse(JSON.stringify(blankPreviousnData))
    }

    fetchOptions({ nombreDominio: 'Clientes' }, data => {
      customers.value = data
    })

    fetchOptions({ nombreDominio: 'RecintosFiscales' }, data => {
      recintosFiscales.value = data
    })

    const filesTableFields = [
      {
        key: 'nombreDocumento',
        label: i18n.t('AppPrevious.AddEdit.documentName'),
        sortable: false,
      },
      {
        key: 'previoDocumentoTipo.nombre',
        label: i18n.t('AppPrevious.AddEdit.typePreviousDocument'),
        sortable: false,
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
        sortable: false,
      },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    /* Events */
    const onUploadDocuments = () => {
      isUploadingDocuments.value = true

      createPreviousDocument(previousData.value.previoId, filesUploaded.value.map(m => ({
        guid: m.guid,
        archivo: m.nombre,
        previoDocumentoTipoId: auxData.value.previoDocumentoTipo.id,
      })),
      () => {
        refFilesUploader.value.deleteFiles()
        fetchPreviousDocuments(previousData.value.previoId, dataDocuments => {
          previousData.value.previoDocumentos = dataDocuments
          previousDocumentsData.value = dataDocuments
        })
        isUploadingDocuments.value = false
      })
    }

    const onDeteleDocument = document => {
      deletePreviousDocument(document.previoId, document.previoDocumentoId, () => {
        previousDocumentsData.value = previousDocumentsData.value.filter(f => f.guid !== document.guid)
      })
    }
    const onDownloadFile = item => {
      fetchPreviousDocument(item.guid, data => {
        const file = new File([data], `${item.archivo}.${item.extension}`)
        saveAs(file, `${item.archivo}.${item.extension}`)
      })
    }

    const formSubmitted = () => {
      endingPrevious.value = true
      partialUpdatePrevious(previousData.value.previoId, [
        {
          value: 3,
          path: 'previoEstadoId',
          op: 'replace',
        },
      ], () => {
        endingPrevious.value = false
        router.push({ name: 'apps-previous-list' })
      })
    }

    /* Validations */
    const validationGeneral = () => new Promise((resolve, reject) => {
      refGeneralValidationRules.value.validate().then(success => {
        if (success) {
          if (previousData.value.previoId !== 0) {
            updatePrevious(previousData.value, () => {
            })
          } else {
            createPrevious(previousData.value, data => {
              previousData.value.previoId = data.previoId
              previousData.value.conversacionId = data.conversacionId

              refConversation.value.refetchData(previousData.value.conversacionId)
            })
          }
          resolve(true)
        } else {
          reject()
        }
      })
    })

    const validateDocuments = () => new Promise((resolve, reject) => {
      damageRequired.value = previousData.value.bultoDañado && previousDocumentsData.value.filter(document => document.previoDocumentoTipoId === 1).length < 1
      guideRequired.value = previousData.value.recintoFiscal.textoAlterno === 'Aérea' && previousDocumentsData.value.filter(document => document.previoDocumentoTipoId === 3).length < 1

      if (guideRequired.value || damageRequired.value) {
        reject()
      } else {
        resolve(true)
      }
    })
    const validationPartidas = () => new Promise((resolve, reject) => {
      if (previosPartidasCount.value > 0 && partidasPhotosCompleted.value) {
        resolve(true)
      } else {
        reject()
      }
    })
    const validationObservations = () => new Promise((resolve, reject) => {
      refObservationsValidation.value.validate().then(success => {
        if (success) {
          partialUpdatePrevious(previousData.value.previoId, [
            {
              value: previousData.value.observaciones,
              path: 'Observaciones',
              op: 'replace',
            },
          ], () => {
            resolve(true)
          })
        } else {
          reject()
        }
      })
    })

    const showMaritima = computed(() => previousData.value.recintoFiscal && previousData.value.recintoFiscal.textoAlterno === 'Marítima' && previousData.value.previoCargaTipoId !== 2)
    const vendorToggleIcon = computed(() => (vendorWrite.value ? 'ListIcon' : 'Edit3Icon'))
    return {
      refGeneralValidationRules,
      refDocumentsValidation,
      refPartidasValidation,
      refObservationsValidation,
      refConversation,
      isUploadingDocuments,

      guideRequired,
      damageRequired,
      vendorWrite,
      refFilesUploader,

      previousData,
      previousDocumentsData,
      previosPartidasCount,
      partidasPhotosCompleted,
      auxData,
      filesUploaded,
      recintosFiscales,
      customers,
      vendors,
      fetchVendors,
      fetchLineasTransporte,

      lineasTransportes,

      required,
      requiredIf,

      refFormObserver,
      getValidationState,
      resetForm,

      onUploadDocuments,
      onDeteleDocument,
      onDownloadFile,
      filesTableFields,
      endingPrevious,

      formSubmitted,
      // Validation
      validationGeneral,
      validateDocuments,
      validationPartidas,
      validationObservations,
      // Computed
      showMaritima,
      vendorToggleIcon,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
