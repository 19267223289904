<template>
  <div>
    <b-alert
      v-if="partidas.length < 1"
      show
      dismissible
      fade
      class="mt-1"
      variant="warning"
    >
      <div class="alert-body">
        {{ $t('AppPrevious.AddEdit.Departed.captureDeparted') }}
      </div>
    </b-alert>
    <b-alert
      v-if="!partidasPhotosCompleted"
      show
      dismissible
      fade
      class="mt-1"
      variant="warning"
    >
      <div class="alert-body">
        {{ $t('AppPrevious.AddEdit.Departed.alertPhotoDeparted') }}
      </div>
    </b-alert>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="8"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('Lists.Show') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>{{ $t('Lists.Entries') }}</label>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="4"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              data-inputmask="'alias': 'currency'"
              :placeholder="$t('Lists.Search')"
            />
            <b-button
              variant="primary"
              @click="onNewPartida"
            >
              <span class="text-nowrap">{{ $t('AppPrevious.AddEdit.Departed.newDeparted') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refPartidasTable"
      responsive
      :items="refetchPartidas"
      :fields="partidasTableFields"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('Lists.NoMatchingRecordsFound')"
      :sort-desc.sync="isSortDirDesc"
    >
      <template
        #cell(actions)="data"
      >
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="onSelectPartida(data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">{{ $t('Lists.Edit') }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="onDeletePartida(data.item)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.Showing }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPartidas"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal
      v-if="partidaSelected"
      id="modal-partidas"
      v-model="showEditPartidaModal"
      :title="$t('AppPrevious.AddEdit.Departed.previousDepartedTittle')"
      size="xl"
    >
      <validation-observer
        ref="refPartidaRules"
        tag="form"
      >
        <b-row>
          <!--Numero factura -->
          <b-col
            v-if="customType !== 'Marítima'"
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Numero factura"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.billNumber')"
              >
                <b-form-input
                  v-model="partidaSelected.numeroFactura"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Numero de parte-->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Numero de parte"
              :rules="{required: customType !== 'Marítima'}"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.partNumber')"
              >
                <b-form-input
                  v-model="partidaSelected.numeroParte"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Descripcion-->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Descripcion"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.description')"
              >
                <b-form-input
                  v-model="partidaSelected.descripcion"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Cantidad fisica-->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Cantidad fisica"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.physicalAmount')"
              >
                <b-form-input
                  v-model="partidaSelected.cantidadFisica"
                  v-mask="currencyMask"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
              <!--AQUI-->
            </validation-provider>
          </b-col>
          <!--Unidad de medida-->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Unidad de medida"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.unitMeasure')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="partidaSelected.unidadMedidaFacturaId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="unidadesMedida"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Pais origen -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Pais origen"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.countryOrigin')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="partidaSelected.paisOrigenId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="countries"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Cantidad factura-->
          <b-col
            v-if="customType !== 'Marítima'"
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Cantidad factura"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.billAmount')"
              >
                <b-form-input
                  v-model="partidaSelected.cantidadFactura"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Marca-->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Marca"
              :rules="{ required:customType !== 'Marítima' }"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.mark')"
              >
                <b-form-input
                  v-model="partidaSelected.marca"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Modelo-->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Modelo"
              :rules="{ required:customType !== 'Marítima' }"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.model')"
              >
                <b-form-input
                  v-model="partidaSelected.modelo"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Serie-->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Serie"
              :rules="{ required:customType !== 'Marítima' }"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.serialNumber')"
              >
                <b-form-input
                  v-model="partidaSelected.serie"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Bultos partida-->
          <b-col
            v-if="customType !== 'Marítima'"
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Bultos partida"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.packagesDeparted')"
              >
                <b-form-input
                  v-model="partidaSelected.bultosPartida"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Peso partida-->
          <b-col
            v-if="customType !== 'Marítima'"
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Peso partida"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.departedWeight')"
              >
                <b-form-input
                  v-model="partidaSelected.pesoPartida"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Orden compra-->
          <b-col
            v-if="customType !== 'Marítima'"
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppPrevious.AddEdit.Departed.purchaseOrder')"
            >
              <b-form-input
                v-model="partidaSelected.ordenCompra"
              />
            </b-form-group>
          </b-col>
          <!-- Pais origen factura -->
          <b-col
            v-if="customType !== 'Marítima'"
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Pais origen factura"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPrevious.AddEdit.Departed.countryOriginBill')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="partidaSelected.paisFacturaId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="countries"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Observaciones-->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppPrevious.AddEdit.Departed.observations')"
            >
              <b-form-textarea
                v-model="partidaSelected.observaciones"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <div v-if="partidaSelected.previoPartidaId > 0">
        <strong>{{ $t('AppPrevious.AddEdit.Departed.photos') }}</strong>
        <multiple-file-uploader
          ref="refFilesUploader"
          v-model="photosUploades"
          class="mb-1"
          :disabled="isUploadingPhotos"
        />
        <b-button
          :disabled="photosUploades.length === 0 || isUploadingPhotos"
          class="mb-1"
          @click="onUploadPhotos"
        >
          <b-spinner v-if="isUploadingPhotos" />
          Cargar fotografia (s)
        </b-button>
        <b-table
          :fields="filesTableFields"
          :items="partidasFotos"
        >
          <template
            #cell(nombreDocumento)="data"
          >
            {{ data.item.archivo }}.{{ data.item.extension }}
          </template>
          <template
            #cell(actions)="data"
          >
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="onDeletePhoto(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="imageExtensionsAllowed.includes(data.item.extension)"
                @click="onPreviewPhoto(data.item)"
              >
                <feather-icon icon="DownloadCloudIcon" />
                <span class="align-middle ml-50">{{ $t('AppPrevious.AddEdit.Departed.viewPhoto') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-alert
          v-if="partidasFotos.length < 1"
          show
          dismissible
          fade
          class="mt-1"
          variant="warning"
        >
          <div class="alert-body">
            {{ $t('AppPrevious.AddEdit.Departed.merchandisePhotoAlert') }}
          </div>
        </b-alert>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-if="partidaSelected.previoPartidaId === 0 && !savingChanges"
            variant="primary"
            class="float-right"
            @click="validationPartida(() => {
              onAddPartida()
            })"
          >
            {{ $t('AppPrevious.AddEdit.Departed.saveDeparted') }}
          </b-button>
          <b-button
            v-if="partidaSelected.previoPartidaId > 0 && !savingChanges"
            variant="primary"
            class="float-right"
            @click="validationPartida(()=> {
              onEditPartida()
            })"
          >
            {{ $t('AppPrevious.AddEdit.Departed.finish') }}
          </b-button>
        </div>
      </template>
      <b-modal
        id="modal-partidas"
        v-model="showImageModal"
        :title="imageSelected.nombre"
        size="xl"
      >
        <b-img
          :src="imageSelected.url"
          fluid-grow
          alt="Responsive image"
        />
        <template #modal-footer>
          <span />
        </template>
      </b-modal>
      <b-modal
        id="modal-quantity-confirmation"
        v-model="showQuantityConfirmationModal"
        :title="$t('AppPrevious.AddEdit.Departed.confirmTitle')"
        ok-variant="primary"
        :ok-title="$t('AppPrevious.AddEdit.Departed.okTitle')"
        :cancel-title="$t('AppPrevious.AddEdit.Departed.cancelTitle')"
      >
        {{ $t('AppPrevious.AddEdit.Departed.messageConfirm') }}
      </b-modal>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInvalidFeedback,
  BFormTextarea,
  BImg,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import MultipleFileUploader from '@/components/MultipleFileUploader.vue'
import { ref, watch, computed } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import previousService from '@/services/previous.service'
import optionService from '@/services/option.service'
import i18n from '@/libs/i18n'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export default {

  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormGroup,
    BModal,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,
    BFormTextarea,
    BImg,
    BAlert,
    BSpinner,

    MultipleFileUploader,

    vSelect,

    // Cleave,

    ValidationObserver,
    ValidationProvider,
  },
  props: {
    previousId: {
      type: Number,
      required: true,
    },
    partidasCount: {
      type: Number,
      required: true,
    },
    partidasPhotosCompleted: {
      type: Boolean,
      required: true,
    },
    customType: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit, root }) {
    /* Services */
    const {
      fetchPreviousPartidas,
      createPreviousPartida,
      updatePreviousPartida,
      deletePreviousPartida,
      fetchPreviousPartidaPhotos,
      createPreviousPartidaPhoto,
      deletePreviousPartidaPhoto,
    } = previousService()
    const {
      fetchOptions,
    } = optionService()
    /* Refs */
    const refPartidasTable = ref(null)
    const refPartidaRules = ref(null)
    const isUploadingPhotos = ref(false)
    const refFilesUploader = ref(null)

    /* Pagination */
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const currentPage = ref(1)
    /* Params */
    const searchQuery = ref('')
    const sortBy = ref('PrevioPartidaId')
    const isSortDirDesc = ref(false)
    /* Data */
    const totalPartidas = ref(0)
    const partidas = ref([])
    const partidasFotos = ref([])
    const partidasPhotosCompleted = ref(props.partidasPhotosCompleted)
    const photosUploades = ref([])

    const blankPrevioPartidaData = {
      previoPartidaId: 0,
      numeroFactura: '',
      numeroParte: '',
      descripcion: '',
      cantidadFisica: null,
      unidadMedidaFacturaId: null,
      paisOrigenId: null,
      cantidadFactura: null,
      marca: '',
      modelo: '',
      serie: '',
      bultosPartida: null,
      pesoPartida: null,
      ordenCompra: '',
      paisFacturaId: '',
      observaciones: '',
    }

    const partidaSelected = ref(JSON.parse(JSON.stringify(blankPrevioPartidaData)))
    const imageSelected = ref({
      nombre: '',
      url: '',
    })

    const partidasTableFields = [
      {
        key: 'numeroFactura',
        label: i18n.t('AppPrevious.AddEdit.Departed.billLabel'),
        sortable: false,
      },
      {
        key: 'numeroParte',
        label: i18n.t('AppPrevious.AddEdit.Departed.part'),
        sortable: false,
      },
      {
        key: 'descripcion',
        label: i18n.t('AppPrevious.AddEdit.Departed.description'),
        sortable: false,
      },
      {
        key: 'cantidadFisica',
        label: i18n.t('AppPrevious.AddEdit.Departed.physicalAmount'),
        sortable: false,
      },
      {
        key: 'pesoPartida',
        label: i18n.t('AppPrevious.AddEdit.Departed.weight'),
        sortable: false,
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
        sortable: false,
      },
    ]

    const refetchPartidas = (ctx, callback) => {
      fetchPreviousPartidas(props.previousId, {
        searchQuery: searchQuery.value,
        limit: perPage.value,
        offSet: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
        sort: `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
      }, data => {
        partidas.value = data.previoPartidas
        totalPartidas.value = data.total
        emit('update:partidas-count', totalPartidas.value)
        const partidasWithoutPhotos = partidas.value.filter(f => !f.previoPartidaFotos || f.previoPartidaFotos.length === 0)
        partidasPhotosCompleted.value = partidasWithoutPhotos.length === 0
        emit('update:partidas-photos-completed', partidasPhotosCompleted.value)
        callback(partidas.value)
      })
    }

    const dataMeta = computed(() => {
      const localItemsCount = refPartidasTable.value ? refPartidasTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalPartidas.value,
      }
    })

    const refreshData = () => {
      refPartidasTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
      refreshData()
    })

    const unidadesMedida = ref([])
    const countries = ref([])

    fetchOptions({ nombreDominio: 'UnidadesMedida' }, data => { unidadesMedida.value = data })
    fetchOptions({ nombreDominio: 'Paises' }, data => { countries.value = data })
    /* UI */
    const showEditPartidaModal = ref(false)
    const showImageModal = ref(false)
    const showQuantityConfirmationModal = ref(false)
    const savingChanges = ref(false)

    const checkQuantity = callback => {
      if (props.customType === 'Aérea' && (partidaSelected.value.cantidadFisica !== partidaSelected.value.cantidadFactura)) {
        root.$bvModal
          .msgBoxConfirm(`${i18n.t('AppPrevious.AddEdit.Departed.diferenceAmount')} ${partidaSelected.value.cantidadFisica}, ${i18n.t('AppPrevious.AddEdit.Departed.billLabel')} ${partidaSelected.value.cantidadFactura}, ${i18n.t('AppPrevious.AddEdit.Departed.isCorrect')}`, {
            title: i18n.t('Lists.pleaseConfirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: i18n.t('Lists.Yes'),
            cancelTitle: i18n.t('Lists.No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(confirmation => {
            if (confirmation) {
              partidaSelected.value.observaciones = `Diferencia en cantidades, fisica ${partidaSelected.value.cantidadFisica}, factura ${partidaSelected.value.cantidadFactura}.`
              callback()
            }
          })
      } else {
        callback()
      }
    }

    const onNewPartida = () => {
      if (partidasPhotosCompleted.value) {
        partidaSelected.value = JSON.parse(JSON.stringify(blankPrevioPartidaData))
        showEditPartidaModal.value = true
        partidasFotos.value = []
      }
    }

    const onAddPartida = () => {
      checkQuantity(() => {
        savingChanges.value = true
        createPreviousPartida(props.previousId, {
          previoPartidaId: partidaSelected.value.previoPartidaId,
          numeroFactura: partidaSelected.value.numeroFactura,
          numeroParte: partidaSelected.value.numeroParte,
          descripcion: partidaSelected.value.descripcion,
          cantidadFisica: typeof partidaSelected.value.cantidadFisica === 'string' ? partidaSelected.value.cantidadFisica.replace(/,/g, '') : partidaSelected.value.cantidadFisica,
          unidadMedidaFacturaId: partidaSelected.value.unidadMedidaFacturaId,
          paisOrigenId: partidaSelected.value.paisOrigenId,
          cantidadFactura: partidaSelected.value.cantidadFactura,
          marca: partidaSelected.value.marca,
          modelo: partidaSelected.value.modelo,
          serie: partidaSelected.value.serie,
          bultosPartida: partidaSelected.value.bultosPartida,
          pesoPartida: partidaSelected.value.pesoPartida,
          ordenCompra: partidaSelected.value.ordenCompra,
          paisFacturaId: partidaSelected.value.paisFacturaId,
          observaciones: partidaSelected.value.observaciones,
        }, data => {
          partidaSelected.value = data
          savingChanges.value = false
          refreshData()
        })
      })
    }
    const onSelectPartida = item => {
      partidaSelected.value = item
      partidasFotos.value = item.previoPartidaFotos
      showEditPartidaModal.value = true
    }

    const onEditPartida = () => {
      checkQuantity(() => {
        savingChanges.value = true
        updatePreviousPartida({
          previoId: partidaSelected.value.previoId,
          previoPartidaId: partidaSelected.value.previoPartidaId,
          numeroFactura: partidaSelected.value.numeroFactura,
          numeroParte: partidaSelected.value.numeroParte,
          descripcion: partidaSelected.value.descripcion,
          cantidadFisica: typeof partidaSelected.value.cantidadFisica === 'string' ? partidaSelected.value.cantidadFisica.replace(/,/g, '') : partidaSelected.value.cantidadFisica,
          unidadMedidaFacturaId: partidaSelected.value.unidadMedidaFacturaId,
          paisOrigenId: partidaSelected.value.paisOrigenId,
          cantidadFactura: partidaSelected.value.cantidadFactura,
          marca: partidaSelected.value.marca,
          modelo: partidaSelected.value.modelo,
          serie: partidaSelected.value.serie,
          bultosPartida: partidaSelected.value.bultosPartida,
          pesoPartida: partidaSelected.value.pesoPartida,
          ordenCompra: partidaSelected.value.ordenCompra,
          paisFacturaId: partidaSelected.value.paisFacturaId,
          observaciones: partidaSelected.value.observaciones,
        }, () => {
          let record = partidas.value.filter(f => f.previoPartidaId === partidaSelected.value.previoPartidaId)
          if (record) {
            record = partidaSelected.value
          }
          savingChanges.value = false
          showEditPartidaModal.value = false
        })
      })
    }
    const onDeletePartida = item => {
      deletePreviousPartida(props.previousId, item.previoPartidaId, () => {
        refreshData()
      })
    }

    const onUploadPhotos = () => {
      isUploadingPhotos.value = true
      createPreviousPartidaPhoto(props.previousId, partidaSelected.value.previoPartidaId, photosUploades.value.map(m => ({
        guid: m.guid,
        archivo: m.nombre,
      })), () => {
        fetchPreviousPartidaPhotos(props.previousId, partidaSelected.value.previoPartidaId, data => {
          partidasFotos.value = data
          partidaSelected.value.partidaFotos = data
          refreshData()
        })
        refFilesUploader.value.deleteFiles()
        isUploadingPhotos.value = false
      })
    }

    const onDeletePhoto = item => {
      deletePreviousPartidaPhoto(props.previousId, item.previoPartidaId, item.previoPartidaFotoId, () => {
        partidasFotos.value = partidasFotos.value.filter(f => f.previoPartidaFotoId !== item.previoPartidaFotoId)
        refreshData()
      })
    }
    const onPreviewPhoto = item => {
      imageSelected.value.nombre = item.archivo
      imageSelected.value.url = `${process.env.VUE_APP_ADUACORE_API_URL}/api/Previos/Partidas/Fotos/${item.guid}`
      showImageModal.value = true
    }

    const filesTableFields = [
      {
        key: 'nombreDocumento',
        label: i18n.t('AppPrevious.AddEdit.Departed.photo'),
        sortable: false,
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
        sortable: false,
      },
    ]
    const imageExtensionsAllowed = ref([
      'png',
      'PNG',
      'jpeg',
      'JPEG',
      'jpg',
      'JPG',
    ])
    const {
      getValidationState,
    } = formValidation()

    const validationPartida = callback => {
      refPartidaRules.value.validate().then(success => {
        if (success) {
          callback()
        }
      })
    }

    const currencyMask = createNumberMask({
      prefix: '',
      allowDecimal: true,
      includeThousandsSeparator: true,
      allowNegative: false,
    })

    return {
      refPartidasTable,
      refPartidaRules,
      isUploadingPhotos,
      refFilesUploader,

      perPageOptions,
      perPage,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      partidaSelected,
      imageSelected,
      partidasTableFields,
      partidas,
      totalPartidas,
      partidasFotos,
      filesTableFields,
      imageExtensionsAllowed,
      photosUploades,

      dataMeta,

      refetchPartidas,

      unidadesMedida,
      countries,

      required,
      getValidationState,

      showEditPartidaModal,
      showImageModal,
      showQuantityConfirmationModal,
      savingChanges,

      onNewPartida,
      onAddPartida,
      onSelectPartida,
      onEditPartida,
      onDeletePartida,
      onUploadPhotos,
      onDeletePhoto,
      onPreviewPhoto,

      validationPartida,
      currencyMask,
    }
  },
}
</script>

<style>

</style>
